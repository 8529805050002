/*
This file is autogenerated. Don't change it directly.
Instead, change the "addons" setting in your package.json file.
*/

const projectConfigLoader = require('@root/config');
import eeacmsvoltoPdfBlock from '@eeacms/volto-pdf-block';

const addonsInfo = [{"name":"@eeacms/volto-pdf-block","version":"1.0.10","isPublishedPackage":true,"isRegisteredAddon":true,"modulePath":"/opt/frontend/node_modules/@eeacms/volto-pdf-block/src","packageJson":"/opt/frontend/node_modules/@eeacms/volto-pdf-block/package.json","addons":[]}];
export { addonsInfo };

const safeWrapper = (func) => (config) => {
  const res = func(config);
  if (typeof res === 'undefined') {
    throw new Error("Configuration function doesn't return config");
  }
  return res;
}

const projectConfig = (config) => {
  return typeof projectConfigLoader.default === "function" ? projectConfigLoader.default(config) : config;
}

const load = (config) => {
  const addonLoaders = [eeacmsvoltoPdfBlock];
  if(!addonLoaders.every((el) => typeof el === "function")) {
    throw new TypeError(
      'Each addon has to provide a function applying its configuration to the projects configuration.',
    );
  }
  return projectConfig(addonLoaders.reduce((acc, apply) => safeWrapper(apply)(acc), config));
};
export default load;
